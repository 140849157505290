.CardProjects{
    border: none;
    border-radius: 6px;
    box-shadow: 0px 0px 9px 1px lightgray;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 24px;
    margin-top: 1.25rem;
    width: 380px;
    margin-left: 5%;
}
.CardProjects2{
    border: none;
    border-radius: 6px;
    box-shadow: 0px 0px 9px 1px lightgray;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 24px;
    margin-top: 1.25rem;
    width: 360px;
    margin-left: 5%;
    margin-right: 20px;
}
#CardContent{
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
    height: 120px;
}
#CenterContentCard{
    display: flex !important;
    justify-content: center;
    padding-top: 7%;
    font-family: 'Poppins';
    color: #414141;
    font-weight: 500;
    font-size: 14px;
}
#AddProyecto{
    margin-right: 25px;
    position: relative;
    font-family: 'Poppins';
    color: #414141;
    font-weight: 500;
    font-size: 14px;
}
#JustifyDiv{
   padding-top: 10px;
    font-family: 'Poppins';
}
.ImgProyect{
    height: 5rem;
    width: 5rem;
    position: absolute;
}
#CenterContentProyectCard{
    display: flex !important;
    justify-content: center;
    padding-top: 1%;
    font-family: 'Poppins';
    color: #414141;
    font-weight: 500;
    font-size: 14px;
    margin-left: 27%;
    max-height: 90px
}
.DescriptionText{
    font-size: 14px;
    font-weight: 300;
    font-family: Poppins;
    color: #414141;
    margin-right: 20px;
}
#Estrella{
    position: absolute;
    display: flex;
    margin: 52px;
}
#SwitchVista{
    position: relative;
    display: flex;
    float: right;
    background-color: #F3F3F3;
    border-radius: 100px;
    width: 80px;
    height: 30px;
}
#vistaCuad{
    background-color: #FFD52B;
    padding-top: 3%;
    margin-left: 3%;
    border-radius: 100%;
    width: 40px;
    justify-content: center;
    position: relative;
    display: flex;
    color: #414141;
}
#vistaList{
    background-color: #F3F3F3;
    padding-top: 3%;
    margin-left: 3%;
    border-radius: 100%;
    width: 40px;
    justify-content: center;
    position: relative;
    display: flex;
    color: lightgray;
}
#vistaCuad2{
    background-color: #F3F3F3;
    padding-top: 3%;
    margin-left: 3%;
    border-radius: 100%;
    width: 40px;
    justify-content: center;
    position: relative;
    display: flex;
    color: lightgray;
}
#vistaList2{
    background-color: #FFD52B;
    padding-top: 3%;
    margin-left: 3%;
    border-radius: 100%;
    width: 40px;
    justify-content: center;
    position: relative;
    display: flex;
    color: #414141;
}
#addContact{
    color: #414141;
    position: absolute;
    display: flex;
    float: right;
    margin-left: 75%;
    margin-top: -8%;
    cursor: pointer;
}

#tableProjectTitles{
    font-family: "Poppins";
    font-weight: 500;
    color: #414141;
    font-size: 13px;
}
.tableProjectName{
    font-family: "Poppins";
    font-weight: 500;
    color: #000000;
    font-size: 14px;
}
.tableOtherName{
    font-family: "Poppins";
    font-weight: 300;
    color: #000000;
    font-size: 14px;
}
.tableDateName{
    font-family: "Poppins";
    font-weight: 300;
    color: #C4C4C4;
    font-size: 14px;
}

.AddProject{
    background-color: #FFD52B;
    border: none;
    border-radius: 6px;
    width: 145px;
    height: 48px;
    font-family: "Poppins";
    font-size: 16px;
    color: #414141;
    font-weight: 600;
    box-shadow: 0px 0px 15px -5px lightgrey;
    position: fixed;
    bottom: 20px;
    margin-right: 45px;
}
.AddProject:hover{
    background-color: #FFE992;
}
.AddProject:active{
    background-color: #F3C816;
}
#ProjectBudgetCard{
    height: 238px;
    width: 235px ;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%);
    border-radius: 6px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    background-color: #FFFFFF;
}
#circulodeimagen{
    background-color: #FFD52B;
    width: 43px;
    height: 43px;
    border-radius: 100px;
    position: relative;
    display: flex;
    margin-top: 58px;
    justify-content: center;
    padding-top: 8px;
    color: white;

}
#textodebudgeting{
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-family: "Poppins";
    margin-left: 7px;
    margin-top: 52px;
    width: 107px;
}
#todoelcontenido{
    display: flex;
    position: relative;
    padding-top: 25px;

}
#divTotalProyectos{
    height: 900px;
    position: relative;
    display: flex;
    padding-top: 100px;
    width: 100%;
    overflow-x: scroll;
}
#editarProyectos{
    width: 600px;
    position: absolute;
    display: flex;
}
#divPresupuestos{
    margin-left: 550px;
    position: relative;
    display: initial;
    margin-top: -75px;
}
#divactasyordenes{
    margin-left: 40px;
    position: relative;
    display: initial;
    width: 387px;
    margin-top: -75px;
}
#ActasBudgetCard{
    height: 372px;
    width: 335px ;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%);
    border-radius: 6px;
    margin-bottom: 30px;
    display: block;
    justify-content: left;
    padding-left: 20px;
    padding-top: 15px;
  
    background-color: #FFFFFF;
}

#muypronto{
    width: 60px;
    height: 13px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 9px;
    color: white;
    background-color: #002AD4;
    border-radius: 2px;
 
    float: right;
    display: flex;
    position: relative;
    justify-content: center;
    margin-right: 10px;
}
#projectOverflow{
    position: relative;
    display: inline-flex;
    width: auto;
    margin-left: 35px;
}
#TareasBudgetCard{
    height: 500px;
    margin-top: -75px;
    width: 335px ;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%);
    border-radius: 6px;
    margin-bottom: 30px;
    display: block;
    justify-content: left;
    padding-left: 20px;
    padding-top: 15px;
    margin-left: 10px;
    background-color: #FFFFFF;
}
#EquiposBudgetCard{
    height: 243px;

    width: 335px ;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%);
    border-radius: 6px;
    margin-bottom: 30px;
    display: block;
    justify-content: left;
    padding-left: 20px;
    padding-top: 15px;
    margin-left: 10px;
    background-color: #FFFFFF;
}
.AddProjectDisabled{
    background-color: #EFEFEF;
    border: none;
    border-radius: 6px;
    width: 163px;
    height: 48px;
    font-family: "Poppins";
    font-size: 16px;
    color: #495057;
    font-weight: 600;
    box-shadow: 0px 3px 4px lightgray;
}
#SubMenuProjects{
    position: relative;
}
.marginAndDispoistion{
    font-family: "Poppins";
    font-weight: 300;
    font-size: 15px;
    color: #414141;
    padding-right: 45px;
}
.marginAndDispoistionSelected{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 15px;
    color: #414141;
    padding-right: 45px;
}
#totalDiv{
    height: 650px;
    display: flex;
    justify-content: center;
    position: relative;
}
#centradoBudget{
    display:flex;
    justify-content: center;
    margin-top: 0px;
    font-weight: 600;
    Font-family: "Poppins";
    Font-size: 16px;
    color: #414141;
    margin-bottom: 4%;
    margin-top: 5%;
}
#divFotoBudget{
    justify-content: center;
    display: flex;
    margin-top:5%;
}
#focusLittle{
    border-color: #FFD52B;
    width: 40px;
    height: 40px;
    justify-content: center;
    margin:0;
    padding: 0;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    font-family: "Poppins";
    color: #C4C4C4;
}
#focusLittle2{
    border-color: #FFD52B;
    width: 22px;
    height: 17px;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top:10px;
    padding: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    color: #414141;
}
#focusError2{
    border-color: #BF2929;
    width: 22px;
    height: 17px;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 6px;
    margin-top:10px;
    padding: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    font-family: "Poppins";
    color: #414141;
}
#focusError{
    border-color: #BF2929;
    width: 40px;
    height: 40px;
    justify-content: center;
    padding-left: 16px;
    font-size: 13px;
    font-weight: 300;    
    margin:0;
    padding: 0;
    text-align: center;
    font-family: "Poppins";
    color: #C4C4C4;
}
.lightPoppins{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #9F9D9D;
}

_::-webkit-full-page-media, _:future, :root #OtroConten {
    padding-top: 15%;
  }

  _::-webkit-full-page-media, _:future, :root textarea {
    position: -webkit-sticky;
  }

#OpenGoogle{
    width: 100%;
    height: 35px;
    border-radius: 6px;
    border: 1px solid #C4C4C4;
    background-color: white;
    margin-bottom: 15px;
}

#imgGoogle{
    width: 22px;
    width: 22px;
    margin-right: 10px;
   
}


#forgotPass{
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: -3px;
    padding-bottom:10px;
    cursor:pointer;
}
.dropzone2{
    min-height: 230px;
    border: 2px dashed #FFD52B;
    background: #fff;
    border-radius: 6px;
}
#centerPaco{
    position: relative;
    display:flex;
    justify-content: center;
    margin-bottom: -20px;
    margin-top:40px
}
#muypronto2{
    width: 60px;
    height: 13px;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 9px;
    color: white;
    background-color: #002AD4;
    border-radius: 2px;
    opacity: 100% !important;
    float: left;
    display: flex;
    position: absolute;
    justify-content: center;
    margin-left: 15px;
    z-index: 2;
    rotate: -15deg;
}
#PopUpProject{
    position: absolute;
    margin-left: 431px;
    margin-top: -78px;
    width: 200px;
    height: 120px;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #414141;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: none;
}
.textPopUpProject{
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #414141;
    height: 35px;
    padding-left: 16px;
    padding-top: 9px;
    border-radius: 6px;
  
}
.textPopUpProject:hover{
    background-color:  #EFEFEF;
    cursor: pointer;
}
#PopUpProjectShow{
    position: absolute;
    margin-left: 484px;
    margin-top: -4px;
    width: 200px;
    height: 35px;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #414141;
    background-color: white;
    box-shadow: 0px 8px 16px rgb(0 0 0 / 10%);
    border-radius: 6px;
    z-index: 3;
}
.nuevoEnd{
    position: absolute;
    margin-left: 335px;
    margin-top:22px;
    cursor:pointer;
}
#cerrarPopUp{
    position: absolute;
    width: 20px;
    margin-left: 175px;
    margin-top: 5px;
    cursor: pointer;
}
.CancelDel2{
    margin-top: 1%;
    background-color: #FFFFFF;
    border: 2px solid #FFD52B;
    border-radius: 6px;
    width: 145px !important;
    height: 30px;
    font-family: "Poppins";
    font-size: 14px;
    color: #495057;
    font-weight: 500;
    position: absolute;
    margin-left: -160px;
}
.CancelDel2:hover{
    background-color: #FFE992;
    border: 1px solid #FFD52B;
}
.CancelDel2:active{
    background-color: #F3C816;
    border: 1px solid #FFD52B;
}
#addBase{
    position: relative;
    margin-left: -9px;
    margin-right: 3px;
}
.posPronto{
    position: absolute !important;
    margin-left: 300px !important;
    margin-top:100px !important
}