@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.recuadroGeneralAuth{
    width: 50%;
    min-height: 100%;
    background-color: #ffffff;
    padding-left: 1%;
    padding-top: 2%;
    justify-content: center;
}
#centerRecuadro{
    width: 100%;
    display: grid;
    justify-content: center;
    margin-top: 10%;
}
.tituloAuth{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 76px;
    color: #4461D7;
    padding-top: 20px;
}

.subTitleAuth{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #4461D7;
    margin-bottom: 53px;
}
.tituloForm{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #4461D7;
}
#inputsLogin{
    width: 442px;
    background: #FFFFFF;
    border: 1px solid #414141;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #414141;

}
#inputsLogin::placeholder{
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #B7B7B7;

}
#inputsLogin:focus{
    width: 442px;
    background: #FFFFFF;
    border: 1px solid #FFD52B;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}
#forgotPass2{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    width: 150px;
    margin-top: -12px;
    margin-bottom: 10px;
}
.inline-check{
    display: flex;
    position: relative;
}
#rememberAuth{
    margin-top: 9.5px;
    margin-left: 5px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
}
.buttonAuth{
    background-color: #FFD52B;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    border:0px;
    width: 442px;
    height: 48px;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #414141;
}
.buttonAuth:hover{
    background-color: #FFE992;
}
.buttonAuth:active{
    background-color: #F3C816;
}
.solicitarAcceso{
    width: 440px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight:400;
    font-size: 16px;
    line-height: 20px;
    color: #4461D7;
    margin-top:30px;
}
#strongAcces{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #4461D7;
    position: relative;
    display: contents;
    text-decoration: underline #4461D7; 
    cursor: pointer;
}
#feedBack{
    background: #FFFFFF;
    border: 1px solid #D38B8B;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 0px 0px 6px 6px;
    width: 442px;
    margin-top: -3px;
    height: 30px;
    padding-top: 7px;
    padding-left: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #BF2929;
}
#errorAuth{
    width: 17px;
    margin-top: -3px;
    margin-right: 3px;
}
.carousel{
    width: 100%;
    height: 100vh;
    margin-right: 0px;
    padding-right: 0px;
    display: block;
}

.carousel-indicators{
    display: none !important;
}
.carousel-caption h3{
    display: none !important;
}
.carousel-caption p{
    display: none !important;
}

#princContainer{
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;

}
#AquiCarousel{
    width: 50%;height: 100vh;
}
#visibilityOff{
    position: absolute;
    margin-top: -32px;
    margin-left: 405px;
    width: 22px;
    color: #414141;
    display: none
}
#visibilityOn{
    position: absolute;
    margin-top: -32px;
    margin-left: 405px;
    width: 22px;
    color: #414141
}
.pRegister{
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 12px;
    color: #BF2929;
    padding-top: 2.5px;
    padding-left: 2.5px;
}
.showOptionsPass{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 12px;
}
.pRegisterOk{
    color:#3BA452;
    margin-bottom: 0px;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 12px;
    padding-top: 2.5px;
    padding-left: 2.5px;
}
.inline{
    display: flex;
}
#showAdverFalse{
    width: 16px;
    color: #BF2929;
}
#hideAdvertFalse{
    width: 16px;
    color: #BF2929;
    display: none;
}
#showAdverOk{
    width: 16px;
    color: #3BA452;
}
#hideAdvertOk{
    width: 16px;
    color: #3BA452;
    display: none;
}
#Sourcesans{
    font-weight: 300;
    font-family: 'Source Sans Pro';
    font-size: 12px;
    color: black
}

.sidebar-mini #sidebarMuyPronto #muypronto2{
    display: none;
}


#errorLogin{
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 5px;
    color: #BF2929;
    display: none;
}


#errorLoginShow{
    display: flex;
    justify-content: center;
    position: relative;
   margin-top: -40px;
   margin-bottom: 20px;
   padding-bottom: 20px;
    color: #BF2929;
}